import React from "react"
import PropTypes from "prop-types"
import "./layout.css"

const Layout = ({ children }) => (
  <div className={"main"}>
    <main
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {children}
    </main>
    <footer className={"footer"}>
      © {new Date().getFullYear()} SafeguardPass, All rights reserved.
    </footer>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
